@font-face {
    font-family: 'GothamMedium';
    src: url('../assets/fonts/gotham_medium.eot');
    src: url('../assets/fonts/gotham_medium.eot') format('embedded-opentype'),
         url('../assets/fonts/gotham_medium.woff2') format('woff2'),
         url('../assets/fonts/gotham_medium.woff') format('woff'),
         url('../assets/fonts/gotham_medium.ttf') format('truetype'),
         url('../assets/fonts/gotham_medium.svg#gotham_medium') format('svg');
}

@font-face {
    font-family: 'GothamBold';
    src: url('../assets/fonts/GothamBold.eot');
    src: url('../assets/fonts/GothamBold.eot') format('embedded-opentype'),
         url('../assets/fonts/GothamBold.woff2') format('woff2'),
         url('../assets/fonts/GothamBold.woff') format('woff'),
         url('../assets/fonts/GothamBold.ttf') format('truetype'),
         url('../assets/fonts/GothamBold.svg#GothamBold') format('svg');
}

@font-face {
    font-family: 'GothamBook';
    font-weight: 400;
    src: url('../assets/fonts/GothamBook.eot');
    src: url('../assets/fonts/GothamBook.eot') format('embedded-opentype'),
         url('../assets/fonts/GothamBook.woff2') format('woff2'),
         url('../assets/fonts/GothamBook.woff') format('woff'),
         url('../assets/fonts/GothamBook.ttf') format('truetype'),
         url('../assets/fonts/GothamBook.svg#GothamBook') format('svg');
}

.regular {
    font-family: 'GothamBook', sans-serif;
}
.medium {
    font-family: 'GothamMedium', sans-serif;
}
.bold {
    font-family: "GothamBold", sans-serif;
}