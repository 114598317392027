@import url("./styles/_fonts.scss");
@import url("../node_modules/aos/dist/aos.css");
*,
*::after,
*::before {
  box-sizing: border-box;
}
html {
  overflow-x: hidden;
}
body {
  padding: 0;
  margin: 0;
  padding-top: 100px;
  position: relative;
  font-size: 18px;
  overflow-x: hidden;
  color: #000000;
  font-family: "GothamBook", sans-serif;
  @media screen and (max-width: 768px) {
    padding-top: 0px;
    font-size: 16px;
  }
}

b {
  font-weight: normal;
}

a {
  color: #0b2265;
  text-decoration: none;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 1px;
    background-color: #0b2265;
  }
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 20px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "GothamMedium", sans-serif;
  @media screen and (max-width: 768px) {
    font-size: 32px;
  }
}
button,
input,
select,
textarea {
  font-family: "GothamMedium", sans-serif;
}
